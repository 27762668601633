<template>
  <path id="Tracé_560" data-name="Tracé 560" d="M29.076,64V37.832H55.244V64ZM0,64V37.832H26.168V64ZM0,34.924V8.756H26.168V34.924ZM31.951,6.773,57.227,0,64,25.277,38.723,32.05Z"/>
</template>

<script>
export default {
name: "IconFicheLiaison"
}
</script>

<style scoped>

</style>